exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-about-js": () => import("./../../../src/pages/company/about.js" /* webpackChunkName: "component---src-pages-company-about-js" */),
  "component---src-pages-company-careers-js": () => import("./../../../src/pages/company/careers.js" /* webpackChunkName: "component---src-pages-company-careers-js" */),
  "component---src-pages-company-privacy-policy-js": () => import("./../../../src/pages/company/privacy-policy.js" /* webpackChunkName: "component---src-pages-company-privacy-policy-js" */),
  "component---src-pages-company-security-js": () => import("./../../../src/pages/company/security.js" /* webpackChunkName: "component---src-pages-company-security-js" */),
  "component---src-pages-company-team-js": () => import("./../../../src/pages/company/team.js" /* webpackChunkName: "component---src-pages-company-team-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-product-claims-management-js": () => import("./../../../src/pages/product/claims-management.js" /* webpackChunkName: "component---src-pages-product-claims-management-js" */),
  "component---src-pages-product-data-foundation-js": () => import("./../../../src/pages/product/data-foundation.js" /* webpackChunkName: "component---src-pages-product-data-foundation-js" */),
  "component---src-pages-product-fraud-waste-and-abuse-js": () => import("./../../../src/pages/product/fraud-waste-and-abuse.js" /* webpackChunkName: "component---src-pages-product-fraud-waste-and-abuse-js" */),
  "component---src-pages-product-index-js": () => import("./../../../src/pages/product/index.js" /* webpackChunkName: "component---src-pages-product-index-js" */),
  "component---src-pages-product-network-management-js": () => import("./../../../src/pages/product/network-management.js" /* webpackChunkName: "component---src-pages-product-network-management-js" */),
  "component---src-pages-product-network-optimization-js": () => import("./../../../src/pages/product/network-optimization.js" /* webpackChunkName: "component---src-pages-product-network-optimization-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */)
}

